import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'
import { ErrorPadding } from './styledComponents'

/* ErrorBoundary is implemented here to catch component rendering errors
 React Error boundaries do not catch errors from Event handlers or
 Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)

  Async Errors from the api calls returned from the EventPortalService
  and caught by their async await call to update the error State,
  however we throw errors to be caught by this component and show a general failover message
  in the case of network errors and inconsistent data returned from the Event service
  */

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
        this.close = this.close.bind(this)
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            error,
            errorInfo,
        })
    }

    close() {
        this.setState({ hasError: false })
    }

    render() {
        const actions = [{ text: 'Close', onClick: this.close }]

        if (this.state.hasError) {
            return (
                <ModalTransition>
                    {' '}
                    <Modal
                        actions={actions}
                        onClose={this.close}
                        heading={this.error}
                        appearance="danger"
                    >
                        <ErrorPadding>
                            {this.errorInfo ? (
                                <p>this.errorInfo</p>
                            ) : (
                                <p>
                                    Sorry, an unexpected error has occurred,
                                    please logout and try again.
                                </p>
                            )}
                        </ErrorPadding>
                    </Modal>
                </ModalTransition>
            )
        }

        return this.props.children
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
}
export default ErrorBoundary
