import React from 'react'
import SignOutIcon from '@atlaskit/icon/glyph/sign-out'
import { useOktaAuth } from '@okta/okta-react'
import Button from '@atlaskit/button'
import useErrorState from '../stores/useErrorState'
import { TextRight } from './styledComponents'

export default function Logout() {
    const [, setErrorState] = useErrorState()
    const { oktaAuth } = useOktaAuth()

    const logout = async () => {
        oktaAuth.signOut('/')
    }

    const triggerLogOutModal = () => {
        setErrorState({
            hasError: true,
            errorTitle: 'Warning',
            errorAppearance: 'warning',
            errorMessage: 'Are you sure you want to log out?',
            errorActions: [
                { text: 'Logout', onClick: logout, appearance: 'warning' },
            ],
            errorType: 'default',
        })
    }

    return (
        <TextRight>
            <Button
                iconAfter={<SignOutIcon label="Logout icon" size="small" />}
                appearance="subtle"
                onClick={triggerLogOutModal}
            >
                Logout
            </Button>
        </TextRight>
    )
}
