import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { LoginCallback } from '@okta/okta-react'
import Home from './Home'

function App() {
    return (
        <Switch>
            <Route path="/login" component={LoginCallback} />
            <Route path="/" component={Home} />
        </Switch>
    )
}

export default App
