import React, {
    Fragment,
    useEffect,
    useCallback,
    useState,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'
import Pagination from '@atlaskit/pagination'
import EventCard from './EventCard'
import ApproverCard from './ApproverCard'
// import SortBy from './SortBy'
import Loader from './Loader'
import { useEventPortalService } from '../EventPortalService'
import useErrorState from '../stores/useErrorState'
import useEventState from '../stores/useEventState'
import calculateTotalPages from '../tools/calculateTotalPages'
import { Centred } from './styledComponents'
import { useAuth } from '../authContext'
import {
    APPROVER,
    APPROVER_PER_PAGE,
    MANAGER_PER_PAGE,
    SORT_BY,
} from '../consts'
import RejectEvent from './RejectEvent'
import DeleteEvent from './DeleteEvent'
import { setError, handleError } from '../tools/errorHandlers'
import sortByHelper from '../tools/sortArrayByObjectDate'

export default function Feed({
    filterStatus = undefined,
    searchQuery = undefined,
    recursion = undefined,
}) {
    const { userRole } = useAuth()
    const { searchAsManager, searchAsApprover } = useEventPortalService()
    const [isFetching, setIsFetching] = useState(false)
    // const [sortBy, setSortBy] = useState(SORT_BY[0])
    const [sortBy] = useState(SORT_BY[0])
    const [, setErrorState] = useErrorState()

    const [eventState, setEventState] = useEventState()
    const [rejectState, setRejectState] = useState({
        rejectOpen: false,
        idToReject: undefined,
    })
    const [deleteState, setDeleteState] = useState({
        deleteOpen: false,
        idToDelete: undefined,
    })

    const resultsPerPage = (() => {
        return userRole === APPROVER ? APPROVER_PER_PAGE : MANAGER_PER_PAGE
    })()

    const { count, results } = eventState

    const triggerRejection = (id) => {
        setRejectState({
            rejectOpen: true,
            idToReject: id,
        })
    }

    const triggerDeletion = (id) => {
        setDeleteState({
            deleteOpen: true,
            idToDelete: id,
        })
    }
    const removeEvent = useCallback(
        (id) => {
            const filteredResults = eventState.results.filter(
                (event) => event.id !== id
            )

            setEventState((prevEventState) => ({
                ...prevEventState,
                results: filteredResults,
            }))
        },
        [eventState.results, setEventState]
    )

    // the record number of the result we request

    const resultNumber = 0

    const totalPages = calculateTotalPages(count, resultsPerPage)

    const getEvents = useCallback(
        async (skip) => {
            setIsFetching(true)
            const options = {
                statuses: filterStatus,
                term: searchQuery,
                skip,
                recursion,
                take: resultsPerPage,
            }
            let events

            try {
                if (userRole === APPROVER) {
                    events = await searchAsApprover(options)
                } else {
                    events = await searchAsManager(options)
                }
                if (events) {
                    setEventState(events)
                }
            } catch (error) {
                handleError(error, {
                    400: () => setError(error, setErrorState),
                    401: () => setError(error, setErrorState, 'logout'),
                })
            }

            setIsFetching(false)
        },
        [
            filterStatus,
            searchQuery,
            recursion,
            resultsPerPage,
            setEventState,
            userRole,
            searchAsApprover,
            searchAsManager,
            setErrorState,
        ]
    )

    useEffect(() => {
        if (userRole) {
            getEvents(resultNumber)
        }
    }, [getEvents, userRole])

    const [, setPaginationState] = useState({
        onChangeEvent: 1,
    })

    const pages = [...Array(totalPages)].map((_, i) => i + 1)

    const handlePageChange = (event, newPage) => {
        setPaginationState({ onChangeEvent: newPage })

        // change record number & refetch
        const newResultNumber = (newPage - 1) * resultsPerPage

        getEvents(newResultNumber)
    }

    const sortedResults = useMemo(() => {
        if (results) {
            return sortByHelper(results, sortBy.value)
        }
        return null
    }, [results, sortBy])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {!isFetching && results ? (
                <Fragment>
                    {/* {userRole === APPROVER && (
                        <SortBy
                            value={sortBy}
                            onChange={(value) => setSortBy(value)}
                        />
                    )} */}
                    <div className="event-feed">
                        {sortedResults.map((result) => {
                            if (userRole === APPROVER) {
                                return (
                                    <ApproverCard
                                        key={result.id}
                                        {...result}
                                        triggerRejection={triggerRejection}
                                        triggerDeletion={triggerDeletion}
                                        removeEvent={removeEvent}
                                    />
                                )
                            }

                            return (
                                <EventCard
                                    key={result.id}
                                    {...result}
                                    triggerRejection={triggerRejection}
                                    triggerDeletion={triggerDeletion}
                                    removeEvent={removeEvent}
                                />
                            )
                        })}
                    </div>
                </Fragment>
            ) : (
                <Loader />
            )}
            {!isFetching && count === 0 && (
                <Centred>
                    {userRole === APPROVER ? (
                        <p>
                            You don’t currently have any events to approve,
                            check back another time
                        </p>
                    ) : (
                        <p>
                            You don’t currently have any events, please add an
                            event using the ‘Add event’ button
                        </p>
                    )}
                </Centred>
            )}
            {count > 0 && count > resultsPerPage && (
                <Centred>
                    <Pagination pages={pages} onChange={handlePageChange} />
                </Centred>
            )}
            {userRole === APPROVER ? (
                <RejectEvent
                    rejectState={rejectState}
                    setRejectState={setRejectState}
                    removeEvent={removeEvent}
                />
            ) : (
                <DeleteEvent
                    deleteState={deleteState}
                    setDeleteState={setDeleteState}
                />
            )}
        </div>
    )
}
Feed.propTypes = {
    filterStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    searchQuery: PropTypes.string,
    recursion: PropTypes.string,
}
