import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { parse, isAfter } from 'date-fns'
import { Grid, GridColumn } from '@atlaskit/page'
import { Field, ErrorMessage, Fieldset } from '@atlaskit/form'
import { TimePicker } from '@atlaskit/datetime-picker'
import createTimeIntervals from '../tools/createTimeIntervals'
import { InlinePadding } from './styledComponents'

export default function TimePickers({ startTime = '', endTime = '' }) {
    const times = createTimeIntervals('0:00', '23:45', 15)

    return (
        <Fieldset legend="Event Time">
            <Grid>
                <GridColumn>
                    <InlinePadding>
                        <p>
                            <small>
                                If the event finishes at midnight or later,{' '}
                                <strong>
                                    please select the following day for the end
                                    date.
                                </strong>
                            </small>
                        </p>
                    </InlinePadding>
                </GridColumn>
            </Grid>
            <Grid>
                <GridColumn>
                    <div data-testid="start-time">
                        <Field
                            name="startTime"
                            label="Start Time"
                            defaultValue={startTime}
                            isRequired
                        >
                            {({ fieldProps, error, meta }) => (
                                <Fragment>
                                    <TimePicker
                                        {...fieldProps}
                                        times={times}
                                        selectProps={{
                                            classNamePrefix:
                                                'timepicker-select',
                                        }}
                                    />
                                    {error && !meta.dirtySinceLastSubmit && (
                                        <ErrorMessage>{error}</ErrorMessage>
                                    )}
                                </Fragment>
                            )}
                        </Field>
                    </div>
                </GridColumn>
                <GridColumn>
                    <div data-testid="end-time">
                        <Field
                            name="endTime"
                            label="End Time"
                            defaultValue={endTime}
                            isRequired
                        >
                            {({ fieldProps, error, meta }) => (
                                <Fragment>
                                    <TimePicker
                                        {...fieldProps}
                                        times={times}
                                        selectProps={{
                                            classNamePrefix:
                                                'timepicker-select',
                                        }}
                                    />
                                    {error && !meta.dirtySinceLastSubmit && (
                                        <ErrorMessage>{error}</ErrorMessage>
                                    )}
                                </Fragment>
                            )}
                        </Field>
                    </div>
                </GridColumn>
            </Grid>
        </Fieldset>
    )
}
TimePickers.schema = {
    startTime: yup.string().required('You must select a start time'),
    endTime: yup
        .string()
        .required('You must select an end time')
        .test(
            'is-greater',
            'End time should be after start time',
            // eslint-disable-next-line func-names
            function (value) {
                const { startTime } = this.parent

                return isAfter(
                    parse(value, 'HH:mm', new Date()),
                    parse(startTime, 'HH:mm', new Date())
                )
            }
        ),
}
TimePickers.multiDaySchema = {
    startTime: yup.string().required('You must select a start time'),
    endTime: yup.string().required('You must select an end time'),
}
TimePickers.propTypes = {
    startTime: PropTypes.string,
    endTime: PropTypes.string,
}
