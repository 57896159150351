import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import ModalDialog, {
    ModalFooter,
    ModalTransition,
} from '@atlaskit/modal-dialog'
import InlineMessage from '@atlaskit/inline-message'
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button'
import { useEventPortalService } from '../EventPortalService'
import useErrorState from '../stores/useErrorState'
import { setError, handleError } from '../tools/errorHandlers'

export default function DeleteEvent({ deleteState, setDeleteState }) {
    const [isLoading, setIsLoading] = useState(false)
    const [deletionSuccess, setDeletionSuccess] = useState(false)
    const { cancelEvent } = useEventPortalService()
    const [, setErrorState] = useErrorState()
    const { deleteOpen, idToDelete } = deleteState

    const close = useCallback(() => {
        setDeleteState((prevEventTypeState) => ({
            ...prevEventTypeState,
            deleteOpen: false,
        }))
    }, [setDeleteState])

    const closeOnSuccess = useCallback(
        () => setTimeout(() => close(), 4000),
        [close]
    )

    const handleDelete = async () => {
        setIsLoading(true)
        try {
            const deletionResponse = await cancelEvent(idToDelete)

            if (deletionResponse) {
                setDeletionSuccess(true)
                closeOnSuccess()
            }
        } catch (error) {
            handleError(error, {
                400: () => setError(error, setErrorState),
                401: () => setError(error, setErrorState, 'logout'),
            })
        }

        setIsLoading(false)
    }

    const footer = () => (
        <ModalFooter showKeyline={true}>
            <span />
            {deletionSuccess && (
                <InlineMessage type="confirmation" title="Event Deleted" />
            )}

            <ButtonGroup>
                <Button appearance="default" onClick={close}>
                    Back
                </Button>
                <LoadingButton
                    appearance="danger"
                    onClick={handleDelete}
                    isLoading={isLoading}
                >
                    Cancel Event
                </LoadingButton>
            </ButtonGroup>
        </ModalFooter>
    )

    return (
        <ModalTransition>
            {deleteOpen && (
                <ModalDialog
                    heading="Are you sure you want to cancel?"
                    onClose={close}
                    components={{
                        // eslint-disable-next-line react/prop-types, react/display-name
                        Container: ({ children }) => children,
                        Footer: footer,
                    }}
                >
                    <div style={{ marginBottom: '1rem' }}>
                        <p>
                            Canceling an event is permanent and cannot be
                            undone. If you want to save your event information,
                            please copy & paste it to save on your computer
                        </p>
                    </div>
                </ModalDialog>
            )}
        </ModalTransition>
    )
}

DeleteEvent.propTypes = {
    deleteState: PropTypes.shape({
        deleteOpen: PropTypes.bool,
        idToDelete: PropTypes.string,
    }),
    setDeleteState: PropTypes.func,
}
