import axios from 'axios'
import qs from 'qs'
import createService from './shared/hooks/createService'

const extractValue = (response) => {
    return response.data.data
}

const [EventPortalService, useEventPortalService] = createService({
    api: ({ apiBase }) => {
        const apiDefaults = {
            baseURL: `${apiBase}/`,
        }

        axios.defaults.paramsSerializer = (p) =>
            qs.stringify(p, { arrayFormat: 'repeat' })

        const authedPortalApi = axios.create(apiDefaults)

        return {
            setToken(token) {
                authedPortalApi.defaults.headers.common.Authorization = `Bearer ${token}`
            },
            searchAsManager({
                statuses = undefined,
                term = undefined,
                skip = undefined,
                take = undefined,
                recursion = undefined,
            }) {
                return authedPortalApi
                    .get('SearchAsManager', {
                        params: { statuses, recursion, term, skip, take },
                    })
                    .then(extractValue)
            },
            searchAsApprover({
                statuses = undefined,
                term = undefined,
                skip = undefined,
                take = undefined,
            }) {
                return authedPortalApi
                    .get('SearchAsApprover', {
                        params: { statuses, term, skip, take },
                    })
                    .then(extractValue)
            },
            createEvent({
                category,
                name,
                description,
                internalCategories,
                ticketed,
                startAt,
                endAt,
                allDay,
                recursionPattern,
                recursionCount,
            }) {
                return authedPortalApi
                    .post('Create', {
                        category,
                        name,
                        description,
                        internalCategories,
                        ticketed,
                        startAt,
                        endAt,
                        allDay,
                        recursionPattern,
                        recursionCount,
                    })
                    .then(extractValue)
            },
            getEvent(id) {
                return authedPortalApi
                    .get('Event', {
                        params: { id },
                    })
                    .then(extractValue)
            },
            cancelEvent(id) {
                return authedPortalApi
                    .delete('Event', {
                        params: { id },
                    })
                    .then(extractValue)
            },
            updateEvent({
                id,
                category,
                name,
                description,
                internalCategories,
                ticketed,
                startAt,
                endAt,
                allDay,
                recursionPattern,
                recursionCount,
            }) {
                return authedPortalApi
                    .patch('Event', {
                        id,
                        category,
                        name,
                        description,
                        internalCategories,
                        ticketed,
                        startAt,
                        endAt,
                        allDay,
                        recursionPattern,
                        recursionCount,
                    })
                    .then(extractValue)
            },
            setEventStatus({ id, status, reasons }) {
                const options = {
                    method: 'POST',
                    params: { id, status, reasons },
                    url: 'Workflow',
                }

                return authedPortalApi(options).then(extractValue)
            },
            generateReport({ from, to }) {
                return authedPortalApi
                    .get('GenerateReport', {
                        params: { from, to },
                    })
                    .then((response) => {
                        return response.data
                    })
            },
            getCategories() {
                return authedPortalApi.get('categories').then(extractValue)
            },
            syncApprover() {
                return authedPortalApi.get('SyncApprover')
            },
        }
    },
    noContextError:
        'useEventPortalService hook must be used within a EventPortalService',
})

export { EventPortalService, useEventPortalService }
