import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { LoadingButton } from '@atlaskit/button'

const LoginForm = () => {
    const { oktaAuth } = useOktaAuth()

    const login = async () => oktaAuth.signInWithRedirect()

    return (
        <div className="login" style={{ marginTop: '15px' }}>
            <LoadingButton
                name="submit"
                type="submit"
                appearance="primary"
                onClick={login}
            >
                Login with Okta
            </LoadingButton>
        </div>
    )
}

export default LoginForm
