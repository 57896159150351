import React from 'react'
import { useHistory } from 'react-router-dom'
import { Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import PropTypes from 'prop-types'

export const OktaProvider = ({ config, children }) => {
    const oktaAuth = new OktaAuth({
        issuer: config.okta_issuer,
        clientId: config.okta_client_id,
        redirectUri: `${window.location.origin}/login`,
        scopes: ['openid', 'profile', 'email', 'EventsPortalScope'],
    })

    const history = useHistory()
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(
            toRelativeUrl(originalUri || '/', window.location.origin)
        )
    }

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {children}
        </Security>
    )
}

OktaProvider.propTypes = {
    config: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
}

export default OktaProvider
