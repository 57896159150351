import React, { useState, useCallback, useContext } from 'react'
import { useEventPortalService } from './EventPortalService'
import useErrorState from './stores/useErrorState'
import { APPROVER } from './consts'
import { handleError, logError } from './tools/errorHandlers'

const AuthContext = React.createContext()

function AuthProvider({ ...props }) {
    const { setToken, syncApprover } = useEventPortalService()
    const [, setErrorState] = useErrorState()
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [userRole, setUserRole] = useState(null)

    const setLoginTimeout = useCallback(
        (expiryDate) => {
            if (!expiryDate) {
                return
            }

            setTimeout(
                () => {
                    setErrorState({
                        hasError: true,
                        errorTitle: 'Token Expired',
                        errorAppearance: 'danger',
                        errorMessage: 'Token Expired. Please login again.',
                        errorType: 'logout',
                    })
                },
                expiryDate * 1000 - new Date().getTime()
            )
        },
        [setErrorState]
    )

    const checkTokenCookie = useCallback(
        async (tokenExpiresAt) => {
            const oktaTokenExpiresAt = document.cookie
                .split('; ')
                .find((row) => row.startsWith('oktaTokenExpiresAt='))
                ?.split('=')[1]

            if (
                !oktaTokenExpiresAt ||
                oktaTokenExpiresAt !== tokenExpiresAt.toString()
            ) {
                try {
                    await syncApprover()
                } catch (error) {
                    handleError(error, {
                        400: () => logError(error),
                        401: () => logError(error),
                        404: () => logError(error),
                    })
                }
                document.cookie = `oktaTokenExpiresAt=${tokenExpiresAt}; path=/; max-age=86400`
            }
        },
        [syncApprover]
    )

    const setUserData = useCallback(
        ({ role, token, tokenExpiresAt }) => {
            setUserRole(role)
            setToken(token)
            setLoggedIn(true)
            setLoginTimeout(tokenExpiresAt)

            if (role === APPROVER) {
                checkTokenCookie(tokenExpiresAt)
            }
        },
        [setUserRole, setToken, setLoginTimeout, checkTokenCookie]
    )

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                userRole,
                setUserData,
            }}
            {...props}
        />
    )
}
const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
