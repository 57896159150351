export const setError = (error, action, errorType = 'default') => {
    action({
        hasError: true,
        errorAppearance: 'danger',
        errorTitle: 'Error',
        errorMessage:
            error.response.data.errorMessage || error.response.data.message,
        errorType,
    })
}

export const logError = (error) => {
    // eslint-disable-next-line no-console
    console.error(error)
}

export const handleError = (error, functionList) => {
    if (Object.keys(functionList).includes(error.response.status.toString())) {
        functionList[error.response.status]()
    } else {
        throw new Error(
            error.response.data.errorMessage || error.response.data.message
        )
    }
}
