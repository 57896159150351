import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import PropTypes from 'prop-types'
import Page from '@atlaskit/page'
import Button from '@atlaskit/button'
import SignOutIcon from '@atlaskit/icon/glyph/sign-out'
import { CentredCard, FullScreen } from '../components/styledComponents'

const LogoutPage = () => {
    const { oktaAuth } = useOktaAuth()
    const logout = async () => oktaAuth.signOut('/')

    return (
        <Page>
            <FullScreen>
                <CentredCard data-testid="login">
                    <h1>Unprivileged user</h1>
                    <p>
                        Sorry, you do not have the necessary permissions to use
                        this portal
                    </p>
                    <div style={{ marginTop: '15px' }}>
                        <Button
                            iconAfter={
                                <SignOutIcon label="Logout icon" size="small" />
                            }
                            appearance="danger"
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </div>
                </CentredCard>
            </FullScreen>
        </Page>
    )
}

LogoutPage.propTypes = {
    error: PropTypes.bool,
}

export default LogoutPage
