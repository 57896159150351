import React from 'react'
import Page from '@atlaskit/page'
import LoginForm from '../components/LoginForm'
import { CentredCard, FullScreen } from '../components/styledComponents'

const LoginPage = () => {
    return (
        <Page>
            <FullScreen>
                <CentredCard data-testid="login">
                    <h1>Events Portal</h1>
                    <LoginForm />
                </CentredCard>
            </FullScreen>
        </Page>
    )
}

export default LoginPage
