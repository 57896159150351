import React from 'react'
import { render } from 'react-dom'
import './index.css'
import App from './App'
import Providers from './Providers'
import ErrorBoundary from './components/ErrorBoundary'

fetch('./config.json')
    .then((r) => r.json())
    .then((data) => {
        render(
            <ErrorBoundary>
                <Providers config={data}>
                    <App />
                </Providers>
            </ErrorBoundary>,
            document.getElementById('root')
        )
    })
