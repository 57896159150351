import React, { useState, Fragment } from 'react'
import SearchIcon from '@atlaskit/icon/glyph/search'
import Button from '@atlaskit/button'
import Textfield from '@atlaskit/textfield'
import { SearchContainer } from '../components/styledComponents'
import Feed from '../components/Feed'

export default function Search() {
    const [searchReady, setSearchReady] = useState(false)
    const [searchQuery, setSearchQuery] = useState()

    const updateQuery = (query) => {
        setSearchReady(false)
        setSearchQuery(query)
    }

    const searchEvents = () => {
        setSearchReady(true)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchEvents()
        }
    }

    return (
        <Fragment>
            <SearchContainer>
                <Textfield
                    onChange={({ target }) => updateQuery(target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Search"
                />
                <Button
                    appearance="primary"
                    iconBefore={<SearchIcon label="Search" size="small" />}
                    onClick={() => searchEvents()}
                >
                    Search
                </Button>
            </SearchContainer>

            {searchReady && <Feed searchQuery={searchQuery} />}
        </Fragment>
    )
}
