import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import LoggedInApp from './LoggedInApp'
import LoginPage from './pages/LoginPage'
import LogoutPage from './pages/LogoutPage'
import { useAuth } from './authContext'
import { APPROVER, PUB_MANAGER } from './consts'

const Home = () => {
    const { authState } = useOktaAuth()
    const { setUserData, isLoggedIn } = useAuth()
    const [isUnprivilegedUser, setIsUnprivilegedUser] = useState(false)

    const getUserRole = (claims) => {
        if (
            claims?.EventsPortalGroups?.some((group) => group.endsWith('EventsPortalEditor'))
        ) {
            return APPROVER
        }

        if (/^\d{4,}/.test(claims?.VenueID)) {
            return PUB_MANAGER
        }
        return null
    }

    useEffect(() => {
        if (authState?.isAuthenticated && authState?.accessToken) {
            const data = {
                role: getUserRole(authState.accessToken.claims),
                token: authState.accessToken.accessToken,
                tokenExpiresAt: authState.accessToken.expiresAt,
            }

            if (!data.role || !data.token || !data.tokenExpiresAt) {
                setIsUnprivilegedUser(true)
            } else {
                setUserData(data)
            }
        }
    }, [authState, setUserData])

    if (!authState) {
        return <div>Loading...</div>
    }

    if (isUnprivilegedUser) {
        return <LogoutPage />
    }

    if (authState.isAuthenticated) {
        return isLoggedIn ? <LoggedInApp /> : <div>Loading...</div>
    }

    return <LoginPage />
}

export default Home
