import createStore from '../shared/hooks/createStore'

export const initialState = {
    hasError: false,
    errorTitle: 'Error',
    errorAppearance: 'danger',
    errorMessage: null,
    errorActions: [],
    errorType: 'default',
}

export default createStore({
    initialState,
})
