import React from 'react'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'
import { useOktaAuth } from '@okta/okta-react'
import useErrorState, {
    initialState as initialErrorState,
} from '../stores/useErrorState'

export default function ErrorModal() {
    const [errorState, setErrorState] = useErrorState()
    const {
        errorMessage,
        errorActions,
        errorTitle,
        errorAppearance,
        errorType,
    } = errorState
    const { oktaAuth } = useOktaAuth()

    const logout = async () => oktaAuth.signOut('/')

    const close = () => {
        setErrorState(initialErrorState)
    }

    const defaultAction = [
        { text: 'Close', onClick: close, appearance: 'subtle' },
    ]

    const logoutAction = [
        { text: 'Logout', onClick: logout, appearance: 'danger' },
    ]

    const actions = () => {
        const action = errorType === 'logout' ? logoutAction : defaultAction

        if (errorActions) {
            return [...action, ...errorActions]
        }
        return action
    }

    return (
        <ModalTransition>
            <Modal
                actions={actions()}
                onClose={close}
                heading={errorTitle}
                appearance={errorAppearance}
            >
                {errorMessage}
            </Modal>
        </ModalTransition>
    )
}
