import React, { useState, useCallback, useRef, useEffect } from 'react'
import { Redirect, Prompt, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import shallowequal from 'shallowequal'
import { Grid, GridColumn } from '@atlaskit/page'
import Form, { FormFooter, CheckboxField } from '@atlaskit/form'
import Button, { LoadingButton } from '@atlaskit/button'
import { Checkbox } from '@atlaskit/checkbox'
import encodeEvent from '../tools/encodeEvent'
import processErrors from '../tools/processErrors'
import makeOptions from '../tools/makeOptions'
import { useEventPortalService } from '../EventPortalService'
import useErrorState from '../stores/useErrorState'
import useCategoryState from '../stores/useCategoryState'
import FormIntroText from './FormIntroText'
import StandardEventFields from './StandardEventFields'
import SingleDatePicker from './SingleDatePicker'
import MultiDayDatePickers from './MultiDayDatePickers'
import RecurringEvents from './RecurringEvents'
import TimePickers from './TimePickers'
import { useAuth } from '../authContext'
import { setError, handleError } from '../tools/errorHandlers'
import { Padding, NarrowContainer, MarginRightAuto } from './styledComponents'

const validationOptions = {
    abortEarly: false,
}

export default function AddEventForm() {
    const { createEvent, getCategories } = useEventPortalService()
    const [, setErrorState] = useErrorState()
    const [categoryState, setCategoryState] = useState({
        categories: undefined,
        internalCategories: undefined,
    })
    const { categories, internalCategories } = categoryState
    const [selectedCategories, setSelectedCategories] = useCategoryState()
    const { userRole } = useAuth()
    const [isRecurring, setRecurring] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [isSuccess, setSuccess] = useState(false)
    const [eventTypeState, setEventTypeState] = useState({
        allDay: false,
        multiDay: false,
    })

    const isIncomplete = useRef(false)

    // TODO: this is a temporary fix as selected categories are pulling
    // through from previously viewed events and not clearing on load
    useEffect(() => {
        setSelectedCategories([])
        // eslint-disable-next-line
    }, [])

    /*
        https://reacttraining.com/blog/react-router-v5-1/#usehistory
        version 6 upgrade issue
    */
    const history = useHistory()

    const parsedData = (data) => {
        if (isRecurring) {
            const { recursionCount } = data

            return {
                ...data,
                recursionCount: parseFloat(recursionCount),
            }
        }
        return data
    }

    const toggleEventType = (event) => {
        const { name } = event.target

        setEventTypeState((prevEventTypeState) => ({
            ...prevEventTypeState,
            [name]: !prevEventTypeState[name],
        }))
    }

    const eventSchema = useCallback(() => {
        const { allDay, multiDay } = eventTypeState

        const schema = () => {
            /* eslint-disable operator-linebreak  */
            return {
                ...StandardEventFields.schema,
                ...(multiDay
                    ? MultiDayDatePickers.schema
                    : SingleDatePicker.schema),
                ...(multiDay && TimePickers.multiDaySchema),
                ...(!allDay && !multiDay && TimePickers.schema),
                ...(isRecurring && RecurringEvents.schema),
            }
            /* eslint-enable operator-linebreak */
        }

        return yup.object(schema())
    }, [eventTypeState, isRecurring])

    const handleSubmit = async (data) => {
        const { allDay, multiDay } = eventTypeState

        const schema = eventSchema()

        const validation = await schema
            .validate(data, {
                ...validationOptions,
                context: { role: userRole },
            })
            .catch((err) => {
                return processErrors(err, data)
            })

        if (!shallowequal(validation, parsedData(data))) {
            return validation
        }

        setLoading(true)
        const submitEvent = encodeEvent(
            data,
            allDay,
            multiDay,
            isRecurring,
            selectedCategories
        )

        try {
            const submissionResponse = await createEvent(submitEvent)

            if (submissionResponse) {
                setSuccess(true)
            }
        } catch (error) {
            handleError(error, {
                400: () => setError(error, setErrorState),
                401: () => setError(error, setErrorState, 'logout'),
            })
        }
        isIncomplete.current = false

        return true
    }

    useEffect(() => {
        const getCats = async () => {
            const cats = await getCategories()

            if (cats && Array.isArray(cats)) {
                setCategoryState({
                    categories: makeOptions(
                        cats.filter((cat) => !cat.internal)
                    ),
                    internalCategories: makeOptions(
                        cats.filter((cat) => cat.internal)
                    ),
                })
            }
        }

        if (!categories && !internalCategories) {
            getCats()
        }
    }, [categories, getCategories, internalCategories, setCategoryState])

    if (isSuccess) {
        return <Redirect to="/success" />
    }
    return (
        <div className="add-event" data-testid="add-event-page">
            <Prompt
                when={isIncomplete.current}
                message={(location) =>
                    `Leaving this page will clear the form. Are you sure you want to go to ${location.pathname}?`
                }
            />
            <Form onSubmit={(data) => handleSubmit(data)}>
                {({ formProps, getValues, dirty }) => {
                    if (dirty) {
                        isIncomplete.current = true
                    }
                    return (
                        <form {...formProps} name="text-fields">
                            <NarrowContainer>
                                <FormIntroText />
                                <StandardEventFields {...categoryState} />

                                <Grid>
                                    <GridColumn>
                                        <Padding>
                                            <Checkbox
                                                name="allDay"
                                                value="allDay"
                                                label="All Day Event"
                                                isDisabled={
                                                    eventTypeState.multiDay ||
                                                    isRecurring
                                                }
                                                isChecked={
                                                    eventTypeState.allDay
                                                }
                                                onChange={toggleEventType}
                                            />
                                        </Padding>
                                    </GridColumn>
                                    <GridColumn>
                                        <Padding>
                                            <Checkbox
                                                label="Multi-day Event"
                                                name="multiDay"
                                                value="multiDay"
                                                isDisabled={
                                                    eventTypeState.allDay ||
                                                    isRecurring
                                                }
                                                isChecked={
                                                    eventTypeState.multiDay
                                                }
                                                onChange={toggleEventType}
                                            />
                                        </Padding>
                                    </GridColumn>
                                </Grid>
                                {eventTypeState.multiDay ? (
                                    <MultiDayDatePickers />
                                ) : (
                                    <SingleDatePicker />
                                )}
                                {!eventTypeState.allDay && <TimePickers />}
                                <CheckboxField name="ticketed" value="ticketed">
                                    {({ fieldProps }) => (
                                        <Checkbox
                                            {...fieldProps}
                                            label="Ticketed Event"
                                        />
                                    )}
                                </CheckboxField>
                            </NarrowContainer>
                            <RecurringEvents
                                eventTypeState={eventTypeState}
                                isRecurring={isRecurring}
                                setRecurring={setRecurring}
                                formState={getValues}
                            />
                            <NarrowContainer>
                                <FormFooter>
                                    <MarginRightAuto>
                                        <Button
                                            type="button"
                                            appearance="default"
                                            onClick={() => history.goBack()}
                                        >
                                            Cancel
                                        </Button>
                                    </MarginRightAuto>
                                    <LoadingButton
                                        type="submit"
                                        appearance="primary"
                                        isLoading={isLoading}
                                    >
                                        Submit
                                    </LoadingButton>
                                </FormFooter>
                            </NarrowContainer>
                        </form>
                    )
                }}
            </Form>
        </div>
    )
}
