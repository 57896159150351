import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import {
    parseISO,
    isAfter,
    isBefore,
    add,
    formatISO,
    startOfYesterday,
} from 'date-fns'
import { Field, ErrorMessage } from '@atlaskit/form'
import { DatePicker } from '@atlaskit/datetime-picker'
import { PUB_MANAGER, VALIDATION_ERRORS } from '../consts'
import useSettings from '../shared/hooks/useSettings'

export default function SingleDatePicker({ date = '' }) {
    const { locale } = useSettings()

    return (
        <div>
            <Field
                name="date"
                label="Event Date"
                defaultValue={
                    date || formatISO(Date.now(), { representation: 'date' })
                }
                isRequired
                value={date || null}
            >
                {({ fieldProps, error, meta }) => (
                    <Fragment>
                        <DatePicker
                            {...fieldProps}
                            locale={locale}
                            testId="single-date-picker"
                        />
                        {error && !meta.dirtySinceLastSubmit && (
                            <ErrorMessage>{error}</ErrorMessage>
                        )}
                    </Fragment>
                )}
            </Field>
        </div>
    )
}

SingleDatePicker.schema = {
    date: yup
        .string()
        .required()
        .when('$role', {
            is: PUB_MANAGER,
            then: (schema) =>
                schema.test(
                    'is-in-future',
                    VALIDATION_ERRORS.startDate.pubManager,
                    (value) => {
                        const today = Date.now()

                        return isAfter(parseISO(value), today)
                    }
                ),
            otherwise: (schema) =>
                schema.test(
                    'is-today-or-future',
                    VALIDATION_ERRORS.startDate.approver,
                    (value) => {
                        const yesterday = startOfYesterday()

                        return isAfter(parseISO(value), yesterday)
                    }
                ),
        })
        .test(
            'is-within-1-year',
            'Event Date must be within the next year',
            (value) => {
                const inOneYear = add(Date.now(), { years: 1 })

                return isBefore(parseISO(value), inOneYear)
            }
        ),
}
SingleDatePicker.propTypes = {
    date: PropTypes.string,
}
